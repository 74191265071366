<template>
  <div>
    <loading v-if="isLoading" />
    <!-- <error v-else-if="isError" /> -->
    <content-not-view v-else-if="!subPermission.index" />

    <b-card v-else>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('g.searchHere')"
            type="text"
            class="d-inline-block"
          />
        </div>
        <!-- Add Button -->
        <div v-if="subPermission.store" class="d-flex align-items-center">
          <b-button variant="primary" :to="{ name: routeAddPageName }">
            {{ $t(`g.${routeAddPageName}`) }}
          </b-button>
        </div>
        <!-- ** Add Button -->
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >

        <template slot="table-column" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.label === 'doc_name'" class="text-nowrap">
            {{ $t("g.documentName") }}
          </span>
          <span
            v-else-if="props.column.label === 'doc_number'"
            class="text-nowrap"
          >
            {{ $t("g.documentCode") }}
          </span>
          <span v-else-if="props.column.label === 'release_number'" class="text-nowrap">
            {{ $t("g.issueNumber") }}
          </span>

          <span v-else-if="props.column.label === 'release_date'" class="text-nowrap">
            {{ $t("g.issueDate") }}
          </span>
          <span v-else-if="props.column.label === 'source_name'" class="text-nowrap">
            {{ $t("g.source") }}
          </span>
          <span v-else-if="props.column.label === 'review_number'" class="text-nowrap">
            {{ $t("g.reviewNumber") }}
          </span>
          <span v-else-if="props.column.label === 'review_date'" class="text-nowrap">
            {{ $t("g.reviewDate") }}
          </span>
          <span v-else-if="props.column.label === 'doc_location'" class="text-nowrap">
            {{ $t("g.documentPlace") }}
          </span>
          <span v-else-if="props.column.label === 'saving_duration'" class="text-nowrap">
            {{ $t("g.documentRetentionPeriod") }}
          </span>
          <span v-else-if="props.column.label === 'holding_date'" class="text-nowrap">
            {{ $t("g.holdingDate") }}
          </span>
          <span v-else-if="props.column.label === 'doc_file'" class="text-nowrap">
            {{ $t("g.file") }}
          </span>
          <span v-else-if="props.column.label === 'remind_before_days'" class="text-nowrap">
            {{ $t("g.remindbeforedays") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        >

        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'release_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.release_date) }}
          </span>
          <span
            v-if="props.column.field === 'review_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.review_date) }}
          </span>
          <span
            v-if="props.column.field === 'review_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.review_date) }}
          </span>
          <span
            v-if="props.column.field === 'holding_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.holding_date) }}
          </span>

          <span v-if="props.column.field === 'file_row'" class="text-nowrap">
            <a
              v-if="subPermission.show"
              :href="props.row.file_path"
              class="text-primary"
              target="_blank"
            >
              {{ $t("g.viewDocument") }}
            </a>
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
                class="files-lists"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="subPermission.update"
                  :to="{
                    name: routeEditPageName,
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.destroy"
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> </vue-good-table
    ></b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import pagesData from "./pagesData";
import formatDate from "@/composables/format-date/format-date";
import ContentNotView from "@/views/components/logic/contentNotView.vue";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    ContentNotView,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Error,
    Loading,
    BDropdownItem,
    BDropdown,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "doc_name",
          field: "doc_name",
        },
        {
          label: "doc_number",
          field: "doc_number",
        },
        {
          label: "release_number",
          field: "release_number",
        },
        {
          label: "release_date",
          field: "release_date_row",
        },
        {
          label: "source_name",
          field: "source_name",
        },
        {
          label: "review_number",
          field: "review_number",
        },
        {
          label: "review_date",
          field: "review_date_row",
        },
        {
          label: "doc_location",
          field: "doc_location",
        },
        {
          label: "saving_duration",
          field: "saving_duration",
        },
        {
          label: "holding_date",
          field: "holding_date_row",
        },
        {
          label: "doc_file",
          field: "doc_file",
        },
        {
          label: "remind_before_days",
          field: "remind_before_days",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      routeEditPageName: null,
      routeAddPageName: null,
      isLoading: true,
      isError: false,
      format: null,
      mainPath: null,
      editName: null,
      addName: null,
      subPermission: {},
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  watch: {
    $route(to, from) {
      this.isLoading = true;
      pagesData.forEach((page) => {
        switch (to.name) {
          case page.list:
            this.getTableData(page.tableDataPath, page.editName, page.addName);
            this.mainPath = page.tableDataPath;
            this.editName = page.editName;
            this.addName = page.addName;
            break;
        }
      });
    },
  },
  created() {
    // get data from backend
    pagesData.forEach((page) => {
      switch (this.$route.name) {
        case page.list:
          this.getTableData(
            page.tableDataPath,
            page.editName,
            page.addName,
            page.permission
          );
          this.mainPath = page.tableDataPath;
          this.editName = page.editName;
          this.addName = page.addName;
          break;
      }
    });
    this.format = formatDate;
  },

  methods: {
    getTableData(path, routePageName, routeAddPageName, permission) {
      this.call(path, permission);
      this.routeEditPageName = routePageName;
      this.routeAddPageName = routeAddPageName;
    },

    // call backend
    call(path, permission) {
      this.$http
        .get(`admin/paperTypes/${path}/papers`)
        .then((res) => {
          this.rows = res.data.data;
          setTimeout(() => {
            this.isLoading = false;
            // this.isError = false;
            store.dispatch("GET_PERMISSION", permission);
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.isLoading = false;
            // this.isError = true;
            store.dispatch("GET_PERMISSION", permission);
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        });
    },
    deleteFile(id) {
      this.$http
        .delete(`admin/paperTypes/${this.mainPath}/papers/${id}`)
        .then((res) => {
          if (res.status) {
            this.getTableData(this.mainPath, this.editName, this.addName);
          }
        })
        .catch((err) => console.log(err));
    },
    // Sweet Alert
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!"
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.deleteFile(id);
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.files-lists {
  ul {
    transform: translate3d(-70px, -51px, 0px) !important;
  }
}
</style>

